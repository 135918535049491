<script lang='ts'>
  import type { CanState } from '../../../lib/types/can'
  import type TypeExercice from '../../../exercices/Exercice'
  import { canOptions } from '../../../lib/stores/canStore'
  import CountDown from './presentationalComponents/CountDown.svelte'
  import End from './presentationalComponents/End.svelte'
  import KickOff from './presentationalComponents/KickOff.svelte'
  import Race from './presentationalComponents/Race.svelte'
  import { onMount } from 'svelte'
  import {
    splitExercisesIntoQuestions,
    buildExercisesList,
  } from '../../../lib/components/exercisesUtils'
  import Solutions from './presentationalComponents/Solutions.svelte'
  import { verifQuestionMathLive } from '../../../lib/interactif/mathLive'
  import { verifQuestionQcm } from '../../../lib/interactif/qcm'
  import { verifQuestionListeDeroulante } from '../../../lib/interactif/questionListeDeroulante'
  import {
    indexQuestionCliqueFigure,
    verifQuestionCliqueFigure,
  } from '../../../lib/interactif/cliqueFigure'
  import {
    darkMode,
    exercicesParams,
    globalOptions,
    resultsByExercice,
  } from '../../../lib/stores/generalStore'
  import {
    answersFromCapytale,
    assignmentDataFromCapytale,
    sendToCapytaleSaveStudentAssignment,
  } from '../../../lib/handleCapytale'
  import { millisecondToMinSec } from '../../../lib/components/time'
  import { keyboardState } from '../../keyboard/stores/keyboardStore'
  import type { InterfaceResultExercice } from '../../../lib/types'
  import { context } from '../../../modules/context'
  import { mathaleaUpdateUrlFromExercicesParams } from '../../../lib/mathalea'
  import { get } from 'svelte/store'
  import MetaExercice from '../../../exercices/MetaExerciceCan'
  import { verifDragAndDrop } from '../../../lib/interactif/DragAndDrop'

  let state: CanState = 'canHomeScreen'
  let exercises: TypeExercice[] = []
  let questions: string[] = []
  let consignes: string[] = []
  let corrections: string[] = []
  let consignesCorrections: string[] = []
  let indiceExercice: number[] = []
  let indiceQuestionInExercice: number[] = []
  let resultsByQuestion: boolean[] = []
  let answers: string[] = []
  let recordedTimeFromCapytale: number
  onMount(async () => {
  // handleCapytale peut changer la valeur du store pour que le
  // professeur aille directement aux solutions de l'élève ou pour l'empêcher de recommencer
  canOptions.subscribe((value) => {
    state = value.state
    if (value.state !== 'solutions' && value.state !== 'canHomeScreen') return
    if (answersFromCapytale.length === 0) {
      return
    }
    for (const param of exercises) {
      param.interactif = false
    }

    for (const exercice of answersFromCapytale) {
      if (exercice.answers === undefined) {
        answers.push('')
        continue
      }
      const keys = Object.keys(exercice.answers)
      if (keys.length > 1) {
        const regex = /^Ex\d+Q\d+$/
        const key = keys.find((k) => regex.test(k))
        answers.push(key ? exercice.answers[key] : '')
      } else if (keys.length === 1) {
        const value = exercice.answers[keys[0]]
        if (value?.includes('apiGeomVersion')) {
          answers.push('Voir figure')
          const event = new CustomEvent(keys[0], { detail: value })
          document.dispatchEvent(event)
        } else {
          answers.push(value ? exercice.answers[keys[0]] : '')
        }
      } else {
        answers.push('')
      }
    }
    console.log('answers', answers)

    if (assignmentDataFromCapytale?.resultsByQuestion !== undefined)
      resultsByQuestion = assignmentDataFromCapytale.resultsByQuestion
    if (assignmentDataFromCapytale?.duration !== undefined)
      recordedTimeFromCapytale = assignmentDataFromCapytale.duration
  })
    context.isDiaporama = true
    // force le mode interactif
    globalOptions.update((gOpt) => {
      gOpt.setInteractive = '1'
      return gOpt
    })
    // reconstitution des exercices
    exercises = await Promise.all(buildExercisesList())
    // met à jour la url avec la graine...
    mathaleaUpdateUrlFromExercicesParams(get(exercicesParams))
    // interactivité
    if ($canOptions.isInteractive) {
      $keyboardState.isVisible = true
      for (const param of exercises) {
        param.interactif = true
      }
    }
    // découpage des exerices en questions
    const splitResults = splitExercisesIntoQuestions(exercises)
    questions = [...splitResults.questions]
    consignes = [...splitResults.consignes]
    corrections = [...splitResults.corrections]
    consignesCorrections = [...splitResults.consignesCorrections]
    indiceExercice = [...splitResults.indiceExercice]
    indiceQuestionInExercice = [...splitResults.indiceQuestionInExercice]
    for (let i = 0; i < questions.length; i++) {
      $canOptions.questionGetAnswer.push(false)
    }
  })

  type answerType = {
    type: 'mathlive' | 'fillInTheBlank' | 'dnd' | 'qcm' | 'listeDeroulante' | 'custom' | 'cliqueFigure' | 'unknown'
    index: number
    answers?: { [key: string]: string }
    answerTxt: string
  }

  function checkAnswers() {
    const answersType : answerType[] = []
    for (let i = 0; i < questions.length; i++) {
      const exercice = exercises[indiceExercice[i]]
      const type =  exercice.autoCorrection?.[indiceQuestionInExercice[i]]?.reponse?.param?.formatInteractif ?? exercice.interactifType
      if (type === 'mathlive' || type === 'fillInTheBlank') {
        resultsByQuestion[i] = Boolean(verifQuestionMathLive(exercice, indiceQuestionInExercice[i])?.isOk)
        // récupération de la réponse
        answersType[i] = {
          type,
          index : i,
          answers: { [`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`]: exercice.answers![`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`] },
          answerTxt: exercice.answers![`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`]
        }
        answers[i] = answersType[i].answerTxt          
      } else if (type === 'dnd') {
        resultsByQuestion[i] = verifDragAndDrop(exercice, indiceQuestionInExercice[i]).isOk
        // récupération de la réponse
        answersType[i] = {
          type,
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.startsWith(`rectangleDNDEx${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`) ||
                                    key.startsWith(`texteDNDEx${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.startsWith(`texteDNDEx${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: string, k) => {
                result = exercice.answers![k]
                return result;
              }, '')
        }
        answers[i] = answersType[i].answerTxt
        answersType[i].answers![`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`] = answersType[i].answerTxt
      } else if (type === 'qcm') {
        resultsByQuestion[i] = verifQuestionQcm(exercice, indiceQuestionInExercice[i]) === 'OK'
        // récupération de la réponse
        const propositions = exercice.autoCorrection[indiceQuestionInExercice[i]].propositions
        const qcmAnswers: string[] = []
        propositions!.forEach((proposition, indice: number) => {
          if (exercice.answers![`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}R${indice}`] === '1') {
            if (proposition.texte !== undefined) {
              qcmAnswers.push(proposition.texte)
            }
          }
        })
        answers[i] = qcmAnswers.join(' ; ')
        exercice.answers![`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`] = answers[i]
        answersType[i] = {
          type,
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.startsWith(`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: answers[i]
        }
      } else if (type === 'listeDeroulante') {
        resultsByQuestion[i] = verifQuestionListeDeroulante(exercice, indiceQuestionInExercice[i]) === 'OK'
        answers[i] =
          exercice.answers?.[
            `ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`
          ] ?? exercice.answers?.[
            `Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`
          ] ?? ''
        answersType[i] = {
          type,
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.startsWith(`ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`) || 
                                    key.startsWith(`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: answers[i]
        }
      } else if (type === 'cliqueFigure') {
        resultsByQuestion[i] = verifQuestionCliqueFigure(exercice, indiceQuestionInExercice[i]) === 'OK'
        answers[i] = indexQuestionCliqueFigure(exercice, indiceQuestionInExercice[i] )
        answersType[i] = {
          type,
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.endsWith(`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: answers[i]
        }
      } else if (type === 'custom') {
        // si le type est `custom` on est sûr que `correctionInteractive` existe
        // d'où le ! après `correctionInteractive`
        if (exercice instanceof MetaExercice) {
          const result = exercice.correctionInteractives[indiceQuestionInExercice[i]](indiceQuestionInExercice[i])
          Array.isArray(result) ? resultsByQuestion[i] = result.includes('OK') : resultsByQuestion[i] = result === 'OK'
        } else {
          const result = exercice.correctionInteractive!(indiceQuestionInExercice[i])
          Array.isArray(result) ? resultsByQuestion[i] = result.includes('OK') : resultsByQuestion[i] = result === 'OK'
        }
        answersType[i] = {
          type,
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.endsWith(`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`) || 
                                    key.startsWith(`apigeomEx${indiceExercice[i]}F${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: ''
        }
        answersType[i].answerTxt = Object.keys(answersType[i].answers ?? {}).
                                          filter((key: string, index : number) => index === 0 ).
                                          reduce((result: string, k) => {
                                            result = answersType[i].answers![k]
                                            return result;
                                          }, '')
        answers[i] = answersType[i].answerTxt.includes('apiGeomVersion') ? 'Voir figure' : answersType[i].answerTxt
      } else {
        answersType[i] = {
          type: 'unknown',
          index : i,
          answers: Object.keys(exercice.answers ?? {}).
            filter((key: string) => key.endsWith(`Ex${indiceExercice[i]}Q${indiceQuestionInExercice[i]}`)).
              reduce((result: { [key: string]: any }, k) => {
                result[k] = exercice.answers![k]
                return result;
              }, {}),
          answerTxt: ''
        }

      }
      // Pour Capytale, on a besoin du score de l'exercice et non de la question
      // donc on sauvegarde le score dans l'exercice
      if (resultsByQuestion[i] && exercice.score !== undefined) {
        exercice.score++
      }
    }

    // Désactiver l'interactivité avant l'affichage des solutions
    for (const param of exercises) {
      param.interactif = false
    }
    const resultsByExerciceArray: InterfaceResultExercice[] = []
    for (let i = 0, ind = 0; i < exercises.length; i++) {
      const exercise = exercises[i]
      for (let q = 0; q < exercise.nbQuestions; q++) {
        const quest: InterfaceResultExercice = {
          uuid: exercise.uuid,
          title: exercise.titre,
          indice: exercise.numeroExercice as number,
          state: 'done',
          alea: exercise.seed,
          answers: answersType[ind].answers,
          numberOfPoints: resultsByQuestion[ind] ? 1 : 0,
          numberOfQuestions: 1,
          bestScore: resultsByQuestion[ind] ? 1 : 0,
          resultsByQuestion: [resultsByQuestion[ind]],
          duration: Math.floor(
            $canOptions.durationInMinutes * 60 -
              $canOptions.remainingTimeInSeconds,
          ),
        }
        ind++
        resultsByExerciceArray.push(quest)
      }
    }
    resultsByExercice.update((l) => {
      l = resultsByExerciceArray
      return l
    })
    if ($globalOptions.recorder === 'capytale') {
      if (
        getRecordedScore() > getScoreTotal() ||
        (getRecordedScore() === getScoreTotal() &&
          assignmentDataFromCapytale?.duration &&
          assignmentDataFromCapytale?.duration < getDuration())
      ) {
        return
      }
      sendToCapytaleSaveStudentAssignment({
        indiceExercice: 'all',
        assignmentData: {
          duration: getDuration(),
          resultsByQuestion,
        },
      })
    }
  }

  function getDuration(): number {
    return Math.floor(
      $canOptions.durationInMinutes * 60 - $canOptions.remainingTimeInSeconds,
    )
  }

  /**
   * Construit la chaîne qui sera affichée pour le score
   * nombre de points obtenu / nombre de questions
   */
  function buildStringScore(): string {
    const score = getScoreTotal()
    return score + '/' + resultsByQuestion.length
  }

  function getScoreTotal(): number {
    let score = 0
    for (const result of resultsByQuestion) {
      if (result === true) {
        score++
      }
    }
    return score
  }

  function getRecordedScore(): number {
    let score = 0
    if (assignmentDataFromCapytale?.resultsByQuestion !== undefined) {
      for (const result of assignmentDataFromCapytale.resultsByQuestion) {
        if (result === true) {
          score++
        }
      }
    }
    return score
  }

  /**
   * Construit la chaîne MM:SS qui sera affichée pour le temps mis à faire la course
   */
  function buildTime(): string {
    const nbOfSeconds =
      recordedTimeFromCapytale ||
      $canOptions.durationInMinutes * 60 - $canOptions.remainingTimeInSeconds
    const time = millisecondToMinSec(nbOfSeconds * 1000)
    return [
      time.minutes.toString().padStart(2, '0'),
      time.seconds.toString().padStart(2, '0'),
    ].join(':')
  }
</script>

<div
  class='{$darkMode.isActive
    ? 'dark'
    : ''} relative w-full h-screen bg-coopmaths-canvas dark:bg-coopmathsdark-canvas'
>
  {#if state === 'start' || state === 'canHomeScreen'}
    <KickOff subTitle={$canOptions.subTitle} bind:state />
  {/if}
  {#if state === 'countdown'}
    <CountDown bind:state />
  {/if}
  {#if state === 'race'}
    <Race
      numberOfSeconds={$canOptions.durationInMinutes * 60}
      bind:state
      {questions}
      {consignes}
      {checkAnswers}
    />
  {/if}
  {#if state === 'end'}
    <End bind:state score={buildStringScore()} time={buildTime()} />
  {/if}
  {#if state === 'solutions'}
    <Solutions
      {questions}
      {consignes}
      {corrections}
      {consignesCorrections}
      {answers}
      {resultsByQuestion}
      score={buildStringScore()}
      time={buildTime()}
    />
  {/if}
  <div class='fixed flex bottom-2 right-2'>
    <label
      class='swap swap-rotate text-coopmaths-action hover:text-coopmaths-action-lightest dark:text-coopmathsdark-action dark:hover:text-coopmathsdark-action-lightest'
    >
      <!-- this hidden checkbox controls the state -->
      <input
        id='hidden-checkbox-for-darkmode'
        type='checkbox'
        class='invisible'
        bind:checked={$darkMode.isActive}
      />
      <!-- sun icon -->
      <div class='swap-on'><i class='bx bx-sm bx-sun' /></div>
      <!-- moon icon -->
      <div class='swap-off'><i class='bx bx-sm bx-moon' /></div>
    </label>
  </div>
</div>
